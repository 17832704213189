import { Customer } from "@/interfaces/customer";
import { Order } from "@/interfaces/order";
import { EventBus } from "@/internal";
import { customerService } from "@/services/customer.service";
import { orderService } from "@/services/order.service";
import { orderFulfillmentService } from "@/services/orderFulfillment.service";
import { Callback, TableSuccessModalResponse } from "helix-vue-components";
import find from "lodash/find";
import { TranslateResult } from "vue-i18n";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./PosCustomer.template.vue";

const namespace: string = "OrderModule";

@Component({
  mixins: [Template]
})
export default class PosCustomerComponent extends Vue {
  @Getter("currentCustomer", { namespace: "CustomerModule" })
  public currentCustomer!: Customer;
  @Getter("order", { namespace })
  public order!: Order;
  @Action("setCustomer", { namespace: "CustomerModule" })
  public setCustomer!: Callback;
  @Action("setOrder", { namespace })
  public setOrder!: Callback;
  @Action("customerLimits", { namespace })
  public customerLimits!: Callback;
  @Action("configService", { namespace: "CustomerModule" })
  public configService!: Callback;

  public patientList: Customer[] = [];
  public messageCustomer: string | TranslateResult = "";

  public back() {
    this.$router.push({ name: "point-of-sale" });
  }

  /**
   * This handler checks if the existing customer from the
   * PosCustomerList IS NOT patient (IS ONLY caregiver)  and therefore it shows a modal
   * so the user can select the patient to whom the order will be for
   * @param arg TableSuccessModalResponse
   */
  public async onAddCustomer(arg: TableSuccessModalResponse): Promise<void> {
    const customer = arg.item as Customer;

    if (!this.isPatient(customer)) {
      const patient = await customerService.notPatientSelectedModal(customer);
      arg.unselectModal();
      if (patient) {
        patient.purchasingBy = customer;
        this.addCustomerToPos(patient);
      } else {
        this.back();
      }
    } else {
      this.addCustomerToPos(customer);
    }
  }

  protected async addCustomerToPos(customer: Customer): Promise<void> {
    this.setCustomer(customer);
    const preOrder = await orderFulfillmentService.createCallInPreorder(
      customer.customer_id!
    );

    if (preOrder) {
      const order = (await orderService.find(
        preOrder!.order.id!,
        "preOrder"
      )) as Order;
      order.pre_order!.uid = preOrder.uid;
      // In order to push into the retrieved order the cutomer with purchasingBy because it is not comming from the BE
      order.customer = customer;
      this.setOrder(order);
      this.$router.push({ name: "point-of-sale" });
    }
  }

  protected hasProfileType(customer: Customer, type: string): boolean {
    return !!find(customer.profiles, ["profile_type", type]);
  }

  protected isPatient(customer: Customer) {
    return customer.profiles && this.hasProfileType(customer, "PATIENT");
  }

  protected mounted(): void {
    this.configService(
      this.$route.name === "pos-customers-add" ||
        this.$route.name === "pos-customers-list"
        ? "call-in"
        : "customer"
    );
    EventBus.$once("savedCustomerForCallIn", this.onSavedCustomerForCallIn);
  }

  /**
   * This handler checks if the recently created customer from the
   * CustomersForm IS NOT patient (IS ONLY caregiver) and therefore it shows a modal
   * so the user can select the patient to whom the order will be for
   * @param customer Customer
   */
  protected async onSavedCustomerForCallIn(customer: Customer): Promise<void> {
    this.$nextTick(async () => {
      if (!this.isPatient(customer)) {
        const patient = await customerService.notPatientSelectedModal(customer);
        if (patient) {
          patient.purchasingBy = customer;
          this.addCustomerToPos(patient);
        } else {
          this.back();
        }
      } else {
        this.addCustomerToPos(customer);
      }
    });
  }

  protected beforeUnmount() {
    this.configService(null);
  }
}
